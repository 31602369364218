import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/layouts/Navbar';
import Footer from './components/layouts/Footer';
import HomePage from './components/layouts/HomePage';
import Services from './components/layouts/Services';
import Blogs from './components/layouts/Blogs';
import ErrorPage from './components/layouts/ErrorPage';
import Graphics from './components/layouts/Graphics';
import UiuxDesign from './components/layouts/UiuxDesign';
import WebDevelopemnts from './components/layouts/WebDevelopemnts';
import SinglePost from './components/layouts/SinglePost';



const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:slug" element={<SinglePost />} />
        <Route path="/graphic-design" element={<Graphics />} />
        <Route path="/ui-ux-design" element={<UiuxDesign />} />
        <Route path="/web-developments" element={<WebDevelopemnts />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
