import React, { useState, useEffect } from "react";
import BlurBackground from "../Reuse/BlurBackground";
import Modal from "../Reuse/Modal";
import { API_URL } from "../Reuse/Common";
import Loader from "../Reuse/Loader";
import WorkCard from "../Cards/WorkCard";

const Graphics = () => {
  const [showImageModal, setShowImageModal] = useState(false);
  const [imgData, setImgData] = useState("");
  const [works, setWorks] = useState([]);
  const [showLoadingModal, setShowLoadingModal] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Graphic Design | Kikimo Developers";
    const fetchWork = async () => {
      try {
        const response = await fetch(`${API_URL}/graphic-design`);
        const data = await response.json();
        if (response.ok && data.data) {
          setWorks(data.data);
        } else {
          setError("Failed to fetch works");
        }
      } catch (err) {
        setError("An error occurred while fetching the data");
      } finally {
        setShowLoadingModal(false);
      }
    };

    fetchWork();
  }, []);

  const handleViewImageModal = (imageSrc) => {
    setImgData(imageSrc);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setImgData("");
  };

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <BlurBackground
        layout={
          <div>
            {showLoadingModal && <Loader />}

            {error && <p className="text-red-500">{error}</p>}

            <div className="flex flex-wrap items-center overflow-x-auto overflow-y-hidden py-2 justify-center text-gray-800">
              {works.map((data) => (
                <WorkCard
                  key={data.id}
                  data={data}
                  handleViewImageModal={handleViewImageModal}
                />
              ))}
            </div>
          </div>
        }
      />

      {showImageModal && (
        <Modal
          layout={
            <>
              <img className="rounded h-80" src={imgData} alt="Modal" />
            </>
          }
          handleCloseModal={handleCloseModal}
        />
      )}
    </>
  );
};

export default Graphics;
