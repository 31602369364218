import React, { useState, useEffect } from 'react';
import { API_URL } from '../Reuse/Common';
import BlurBackground from '../Reuse/BlurBackground';
import Loader from '../Reuse/Loader';
import BlogCard from '../Cards/BlogCard';


const Blogs = () => {
    const [posts, setPosts] = useState([]);
    const [pagination, setPagination] = useState({});
    const [showLoadingModal, setShowLoadingModal] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const fetchPosts = async (page = 1) => {
        setShowLoadingModal(true); // Show loader when starting fetch
        try {
            const response = await fetch(`${API_URL}/blogs?page=${page}`);
            const data = await response.json();

            if (response.ok && data.data) { // Check response.ok for HTTP status
                setPosts(data.data);
                setPagination(data);
                setCurrentPage(data.current_page);
                setTotalPages(Math.ceil(data.total / data.per_page)); // Set total pages for pagination
            } else {
                setError('Failed to fetch posts');
            }
        } catch (err) {
            setError('An error occurred while fetching the data');
        } finally {
            setShowLoadingModal(false); // Hide loader after fetch
        }
    };

    useEffect(() => {
        fetchPosts();
        document.title = "Blogs | Kikimo Developers";
    }, []);

    // Function to handle page click
    const handlePageClick = (pageNumber) => {
        if (pageNumber !== currentPage) {
            fetchPosts(pageNumber);
        }
    };


    return (
        <div>
            <BlurBackground
                layout={
                    <div>
                        {showLoadingModal && <Loader />}

                        {error && <p className="text-red-500">{error}</p>}

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {posts.map((data, index) => (
                                <BlogCard key={index} data={data} index={index} />
                            ))}
                        </div>

                        {/* Pagination Controls */}
                        <div className="flex justify-between items-center py-4 bg-white p-4 rounded-lg">
                            {/* Previous Page */}
                            {pagination.prev_page_url && (
                                <button
                                    className="px-4 py-2 bg-gray-200 rounded-md"
                                    onClick={() => handlePageClick(currentPage - 1)}
                                >
                                    Previous
                                </button>
                            )}

                            {/* Page Numbers */}
                            <div className="flex space-x-2">
                                {Array.from({ length: totalPages }, (_, idx) => (
                                    <button
                                        key={idx}
                                        className={`px-4 py-2 rounded-md ${currentPage === idx + 1 ? 'bg-red-500 text-white' : 'bg-gray-200'}`}
                                        onClick={() => handlePageClick(idx + 1)}
                                    >
                                        {idx + 1}
                                    </button>
                                ))}
                            </div>

                            {/* Next Page */}
                            {pagination.next_page_url && (
                                <button
                                    className="px-4 py-2 bg-gray-200 rounded-md"
                                    onClick={() => handlePageClick(currentPage + 1)}
                                >
                                    Next
                                </button>
                            )}
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default Blogs;
